<template>
  <svg viewBox="0 0 22 27" :width="size">
    <switch>
      <g>
        <path
          class="st0"
          d="M17 20.1h-6.3c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7H17c.4 0 .7.3.7.7 0 .4-.3.7-.7.7z"
        />
        <path
          class="st0"
          d="M17 20.1h-6.3c-.4 0-.7-.3-.7-.8s.3-.8.7-.8H17c.4 0 .7.3.7.8s-.3.8-.7.8zm-6.3-1.4c-.4 0-.6.3-.6.7s.3.7.6.7H17c.4 0 .6-.3.6-.7s-.3-.7-.6-.7h-6.3zM8.8 7.4c-.3-.3-.6-.2-.8 0L6 9.7l-1-.9c-.2-.2-.6-.2-.8 0-.3.2-.3.5-.1.8L5.6 11c.1 0 .2.1.4.1s.3-.1.4-.2l2.4-2.7c.3-.3.2-.6 0-.8zM10.7 10.4H17c.4 0 .7-.3.7-.8 0-.4-.3-.8-.7-.8h-6.3c-.4 0-.7.3-.7.8 0 .4.3.8.7.8zM8.8 12.2c-.3-.2-.6-.2-.8.1l-2 2.2-1-.9c-.2-.2-.6-.2-.8 0-.2.2-.2.6 0 .8l1.5 1.4c.1.1.3.2.4.2.2 0 .3-.1.4-.2L8.9 13c.2-.2.1-.6-.1-.8zM17 13.7h-6.3c-.4 0-.7.3-.7.8 0 .4.3.8.7.8H17c.4 0 .7-.3.7-.8 0-.4-.3-.8-.7-.8z"
        />
        <circle class="st0" cx="6.1" cy="19.4" r="1.6" />
        <path
          class="st0"
          d="M21.4 6.4 15.3.5c-.2-.2-.4-.3-.7-.3H3.1C1.4.3 0 1.7 0 3.4v19.8c0 1.7 1.4 3.1 3.1 3.1h15.5c1.7 0 3.1-1.4 3.1-3.1V7.1c0-.2-.1-.5-.3-.7zm-1.9 15.8v1c0 .5-.4 1-1 1H3.1c-.5 0-1-.4-1-1V3.4c0-.5.4-1 1-1h10.5l.1 3.3c0 .7.8 1.2 1.6 1.2h4.2s.1 0 .1.1v15.2z"
        />
      </g>
    </switch>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      default: 19
    }
  }
};
</script>
